<template lang="pug">
v-container
    v-row
        v-spacer
        v-col(cols='8')
            .black--text.text-h5 Rules
            v-card.mb-12(light, flat)
                v-card-text
                    v-simple-table.hoverable
                        template(v-slot:default)
                            thead
                                tr
                                    th Days
                                    th Percent
                                    th Escalate Materials
                                    th Escalate Labor
                                    th Description
                            tbody
                                tr(v-for='rule in rules', :key='rule.id')
                                    td
                                        v-text-field.numberInput(type='number', v-model.number='rule.days', @change='sortRules', min='0')
                                    td
                                        v-text-field.numberInput(type='number', v-model.number='rule.escalation_pct', suffix='%', min='0')
                                    td
                                        v-switch(v-model='rule.materials', color='brand')
                                    td
                                        v-switch(v-model='rule.labor', color='brand')
                                    td.font-weight-thin {{ buildSummary(rule) }}
                                tr
                                    td(colspan='4')
                                        v-spacer
                                        v-btn(text, @click='addRule') Add new rule
                                            v-icon add
                    span.red--text(v-if='uniqueError') Number of days must be unique.
                v-card-actions
                    v-spacer
                    v-btn.white--text(color='green', @click='updateRules', :disabled='uniqueError') Save
            v-overlay(absolute, :value='busy')
                .text-h6 Please Wait...
                v-progress-linear(indeterminate)
        v-spacer

    v-row
        v-col.black--text
            span Escalation is calculated as follows:
            ul
                li
                    span An escalation rule is selected based on the number of 
                    span.blue--text.formulaText days 
                    span between project submission date and estimated start date. The 
                    span.formulaText.red--text escalation_percent 
                    span value is selected from this rule.
                li The escalation rate is calculated using the following formula:
                    ul
                        li
                            span.formulaText.green--text escalation_rate = 
                            span.formulaText.blue--text days 
                            span.formulaText / 30.25 * 
                            span.formulaText.red--text escalation_percent
                    
                li Escalation is to materials and/or labor as determined by the rule. Escalation is calculated like so:
                    ul
                        li
                            span.formulaText materials_escalation = materials_cost * 
                            span.formulaText.green--text escalation_rate
                        li
                            span.formulaText labor_escalation = labor_cost * 
                            span.formulaText.green--text escalation_rate

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ message }}
</template>

<script>
import _ from 'lodash';
export default {
    data (){
        return {
            dti,
            message: '',
            busy: false,
            rules: [],
            ruleTemplate: {
                days: 0,
                escalation_pct: 0,
                materials: false,
                labor: false
            },
            exampleData: {
                materialsCost: 0,
                laborBost: 0,
                days: 0
            },
            exampleRule: null
        };
    },
    methods: {
        async updateRules () {
            let results = await this.sendCommand({
                action: 'updateEscalationRules',
                parameters: this.rules
            });

            if (results) {
                this.message = `Rules updated.`;
            } else {
                this.message = `Failed to update rules.`;
            }
        },
        addRule () {
            let newRule = _.cloneDeep(this.ruleTemplate);
            newRule.id = dti.makeuuid();
            this.rules.push(newRule);
        },
        sortRules () {
            this.rules = _.sortBy(this.rules, 'days');
        },
        buildSummary (rule) {
            let ret = '';
            ret += `After ${rule.days} days, use an escalation % of: ${rule.escalation_pct}%. `;
            if (rule.materials && !rule.labor) {
                ret += 'Only materials will be escalated. ';
            } else if (rule.materials && rule.labor) {
                ret += 'Materials and labor will be escalated. ';
            } else if (!rule.materials && rule.labor) {
                ret += 'Only labor will be escalated. ';
            }
            return ret;
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            }
        },
        uniqueError () {
            let days = this.rules.map((r) => r.days);
            if (new Set(days).size < days.length) {
                return true;
            } else {
                return false;
            }
        }
    },
    mounted (){
        this.socketEmit('getEscalationRules', null, (results) => {
            this.rules = results.rules;
        });
    }
};

</script>

<style lang="scss">
.numberInput {
    width: 100px;
}
.formulaText {
    font-style: italic;
    color: gray;
    font-family: 'courier new', monospace;
}
</style>
